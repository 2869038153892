body {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    width: 300px;
    margin: auto;
}

.website-title {
    font-size: 24px;
    color: #0047ab;
    margin-bottom: 20px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
}

.forgot-password {
    align-self: flex-end;
    margin-bottom: 20px;
    color: #0047ab;
    text-decoration: none;
}

.login-button {
    background-color: #0047ab;
    color: #ffffff;
    width: 300px;
    height: 35px;
    margin-bottom: 20px;;
}

.signup-button_home {
    background-color: #4caf50;
    color: #ffffff;
    width: 300px;
    height: 35px;
}
