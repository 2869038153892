.dropdown {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.menu-button {
    padding: 8px 12px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.menu-list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-top: 5px;
}

.menu-list a {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.menu-list a:last-child {
    border-bottom: none;
}

.menu-list a:hover {
    background-color: #f2f2f2;
}

.survey-container {
    width: 100vw; /* Take up entire viewport width */
    height: 100vh; /* Take up entire viewport height */
    text-align: left;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    box-sizing: border-box; /* This ensures padding is included in the total width/height */
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 30px;
    color: #555;
}

.submit-button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 200px; /* or whatever maximum width you prefer */
}

button:hover {
    background-color: #0056b3;
}

.question-block {
    margin-bottom: 30px;
    text-align: left; /* Align content to the left */
}

.question-block p {
    margin-bottom: 10px;
    font-weight: bold;
}

.question-block div {
    display: inline-block;
    margin-right: 220px;
}

.report-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 8px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
}

h2 {
    font-size: 22px;
    margin-bottom: 10px;
}

h3 {
    font-size: 20px;
    margin-top: 20px;
}

p {
    margin-bottom: 15px;
    color: #555;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

ul li {
    margin-bottom: 10px;
}

.dropdown {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.menu-button {
    padding: 8px 12px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.menu-list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-top: 5px;
}

.menu-list a {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.menu-list a:last-child {
    border-bottom: none;
}

.menu-list a:hover {
    background-color: #f2f2f2;
}

.history-container {
    width: 100vw; /* Take up entire viewport width */
    height: 100vh; /* Take up entire viewport height */
    text-align: left;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    box-sizing: border-box; /* This ensures padding is included in the total width/height */
}

.entry {
    margin-bottom: 40px;
    padding-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

.entry-info {
    text-align: left;
}
