.signup-container {
    width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.placeholder-image {
    width: 100px;
    height: 100px;
    background-color: #d1d1d1;
    border-radius: 50%;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    margin-bottom: 20px;
}

button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
  