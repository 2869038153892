.reset-container {
    width: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    text-align: center;
    margin-bottom: 20px;
}

.email-wrapper {
    width: 95%; /* Make it 100% width of the container */
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.email-container {
    width: 100%; /* Make it fill its wrapper */
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}

.send-code-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0047ab; /* Updated color */
    color: white;
    cursor: pointer;
}
