.dropdown {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.menu-button {
    padding: 8px 12px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.menu-list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-top: 5px;
}

.menu-list a {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.menu-list a:last-child {
    border-bottom: none;
}

.menu-list a:hover {
    background-color: #f2f2f2;
}

.history-container {
    width: 100vw; /* Take up entire viewport width */
    height: 100vh; /* Take up entire viewport height */
    text-align: left;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    box-sizing: border-box; /* This ensures padding is included in the total width/height */
}

.entry {
    margin-bottom: 40px;
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

.entry-info {
    text-align: left;
}

.entry-links a {
    margin-left: 10px; /* Spacing between the links */
    color: #007bff; /* Example link color */
    text-decoration: none; /* Optional: removes underline */
}

.create-election {
    padding: 8px 12px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width: 10rem;
}