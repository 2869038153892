.settings-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 8px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
}

p {
    margin-bottom: 20px;
    color: #555;
}

label {
    display: block;
    margin-bottom: 20px;
}

input[type="text"], input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.narrow-switch {
    width: 7%;
    margin: 7px;
}

.add-button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /*padding: 5px 10px;*/
    font-size: 20px;
    margin: 10px;
    width: 5%;
}

.remove-button {
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /*padding: 5px 10px;*/
    font-size: 20px;
    margin: 10px;
    width: 5%;
}
